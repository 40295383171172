import * as React from 'react';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import { PreviewBody } from 'components';

const PreviewPage = () => (
  <div>
    <PreviewBody />
  </div>
);

export default withPrismicPreviewResolver(PreviewPage);
